import { BiLogoFlask } from "react-icons/bi";
import {
  FaReact,
  FaSwift,
  FaJs,
  FaHtml5,
  FaCss3Alt,
  FaNodeJs,
  FaPython,
  FaWordpress,
  FaDatabase,
} from "react-icons/fa";
import {
  SiNextdotjs,
  SiFlutter,
  SiTypescript,
  SiTailwindcss,
  SiMui,
  SiBootstrap,
  SiDotnet,
  SiApollographql,
  SiExpress,
  SiShopify,
  SiWebflow,
  SiMicrosoftsqlserver,
  SiFirebase,
  SiMongodb,
  SiCanvas,
  SiAndroid,
  SiApple,
  SiSocketdotio,
  SiStripe,
} from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";
import { CiMobile1 } from "react-icons/ci";
import { MdComputer } from "react-icons/md";

const allTags = {
  NextJS: {
    id: 1,
    label: "Next.js",
    icon: SiNextdotjs,
  },
  ReactJS: {
    id: 2,
    label: "React.js",
    icon: FaReact,
  },
  ReactNative: {
    id: 3,
    label: "React Native",
    icon: TbBrandReactNative,
  },
  Swift: {
    id: 4,
    label: "Swift",
    icon: FaSwift,
  },
  Flutter: {
    id: 5,
    label: "Flutter",
    icon: SiFlutter,
  },
  JavaScript: {
    id: 6,
    label: "JavaScript",
    icon: FaJs,
  },
  TypeScript: {
    id: 7,
    label: "TypeScript",
    icon: SiTypescript,
  },
  HTML: {
    id: 8,
    label: "HTML",
    icon: FaHtml5,
  },
  TailwindCSS: {
    id: 9,
    label: "Tailwind CSS",
    icon: SiTailwindcss,
  },
  CSS: {
    id: 10,
    label: "CSS",
    icon: FaCss3Alt,
  },
  MaterialUI: {
    id: 11,
    label: "Material UI",
    icon: SiMui,
  },
  Bootstrap: {
    id: 12,
    label: "Bootstrap",
    icon: SiBootstrap,
  },
  WebAPI: {
    id: 13,
    label: "Web API",
    icon: SiDotnet,
  },
  ApolloGraphQL: {
    id: 14,
    label: "Apollo GraphQL",
    icon: SiApollographql,
  },
  NodeJS: {
    id: 15,
    label: "Node.js",
    icon: FaNodeJs,
  },
  ExpressJS: {
    id: 16,
    label: "Express.js",
    icon: SiExpress,
  },
  Python: {
    id: 17,
    label: "Python",
    icon: FaPython,
  },
  Flask: {
    id: 18,
    label: "Flask",
    icon: BiLogoFlask,
  },
  WordPress: {
    id: 19,
    label: "WordPress",
    icon: FaWordpress,
  },
  Shopify: {
    id: 20,
    label: "Shopify",
    icon: SiShopify,
  },
  Wix: {
    id: 21,
    label: "Wix",
    icon: SiWebflow,
  },
  MicrosoftSqlServer: {
    id: 22,
    label: "Microsoft SQL Server",
    icon: SiMicrosoftsqlserver,
  },
  MySQL: {
    id: 23,
    label: "MySQL",
    icon: FaDatabase,
  },
  Firebase: {
    id: 24,
    label: "Firebase",
    icon: SiFirebase,
  },
  MongoDB: {
    id: 25,
    label: "MongoDB",
    icon: SiMongodb,
  },
  MobileApp: {
    id: 26,
    label: "Mobile App",
    icon: CiMobile1,
  },
  WebApp: {
    id: 27,
    label: "Web App",
    icon: MdComputer,
  },
  Fabric: {
    id: 28,
    label: "Fabric.js",
    icon: SiCanvas,
  },
  Android: {
    id: 29,
    label: "Android",
    icon: SiAndroid,
  },
  iOS: {
    id: 30,
    label: "iOS",
    icon: SiApple,
  },
  socketIo: {
    id: 31,
    label: "Socket.io",
    icon: SiSocketdotio,
  },
  stripe: {
    id: 32,
    label: "Stripe",
    icon: SiStripe,
  },
  firebaseAnalytics: {
    id: 33,
    label: "Firebase Analytics",
    icon: SiFirebase,
  },
};

export const portfolioItems = [
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Wimbo.svg?raw=true",
    title: "Wimbo",
    description:
      "Meet new people, join epic events, and explore what’s happening around you all in one place. Wimbo makes it easy to swipe, connect, and experience life with like-minded people. Whether you're looking for new friends, exciting plans, or exclusive events, Wimbo brings the fun to you.",
    button1Title: "Google Playstore",
    onClick1: () =>
      window.open(
        "https://play.google.com/store/apps/details?id=com.wimbo",
        "_blank"
      ),
    button2Title: "Apple Appstore",
    onClick2: () =>
      window.open("https://apps.apple.com/app/id6739947946", "_blank"),
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.NodeJS,
      allTags.ExpressJS,
      allTags.Firebase,
      allTags.TypeScript,
      allTags.MongoDB,
      allTags.socketIo,
      allTags.Android,
      allTags.iOS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Gringo.png?raw=true",
    title: "Gringo",
    description:
      "Stay Connected Anywhere with Gringo. Gringo makes staying online effortless with seamless mobile data access worldwide. Enjoy unlimited data with blazingly fast speeds, no contracts and no hassle. Simply install the Gringo app, activate your eSIM, and connect instantly to local networks wherever you travel.",
    button1Title: "Google Playstore",
    onClick1: () =>
      window.open(
        "https://play.google.com/store/apps/details?id=com.gringo",
        "_blank"
      ),
    button2Title: "Apple Appstore",
    onClick2: () =>
      window.open("https://apps.apple.com/app/id6723896484", "_blank"),
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.stripe,
      allTags.Firebase,
      allTags.firebaseAnalytics,
      allTags.TypeScript,
      allTags.Android,
      allTags.iOS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Corner%20Stone.svg?raw=true",
    title: "Corner Stone",
    description:
      "Connecting teachers, parents, and school administration with kindergarten kids through interactive tasks, quizzes, audio, and video lessons. Empowering early learning with engaging content designed for growth and development.",
    button1Title: "Google Playstore",
    onClick1: () =>
      window.open(
        "https://play.google.com/store/apps/details?id=com.cornerstone.elearning.companion&pcampaignid=web_share",
        "_blank"
      ),
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.ApolloGraphQL,
      allTags.TypeScript,
      allTags.Android,
      allTags.iOS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Websites/Quick%20PDF.svg?raw=true.svg",
    title: "Quick PDF",
    description:
      "A versatile design tool for creating and exporting custom PDFs, offering users the freedom to add and style shapes, text, images, manage layers, and apply undo/redo actions. Users can share projects, chat, and manage all projects in a personal dashboard, with every essential canvas feature included for seamless creativity.",
    buttonTitle: "",
    tags: [
      allTags.WebApp,
      allTags.ReactJS,
      allTags.Fabric,
      allTags.TypeScript,
      allTags.Firebase,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Islam%20Encyclo.svg?raw=true",
    title: "Islam Encyclo",
    description:
      "A comprehensive Islamic app built with React Native, Firebase, and AWS cloud storage, offering Quran readings, audio Quran, translations, prayer timings, and a Qibla direction finder. This feature-rich platform supports users' daily practices with cloud-backed resources, integrating essential Islamic content into a seamless mobile experience.",
    button1Title: "Google Playstore",
    onClick1: () =>
      window.open(
        "https://play.google.com/store/apps/details?id=com.kivyx.islamencyclo&pcampaignid=web_share",
        "_blank"
      ),
    button2Title: "Apple Appstore",
    onClick2: () =>
      window.open(
        "https://apps.apple.com/my/app/islam-encyclo/id6476152451",
        "_blank"
      ),
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.TypeScript,
      allTags.Firebase,
      allTags.Android,
      allTags.iOS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Websites/Hiks%20Home.svg?raw=true.svg",
    title: "Hiks Homes",
    description:
      "Your gateway to premium living. Explore available plots, view detailed property information, and follow a seamless, transparent process toward owning your dream home.",
    button1Title: "Visit Website",
    onClick1: () => window.open("https://hikshomes.com/", "_blank"),
    tags: [
      allTags.WebApp,
      allTags.ReactJS,
      allTags.JavaScript,
      allTags.TailwindCSS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Sharar%20Parts.svg?raw=true",
    title: "Sharar Parts",
    description:
      "Discover and purchase car parts effortlessly with our app, where users can filter, search, and find the perfect components for their vehicles. Simplify your car maintenance with easy navigation and a vast selection of quality parts.",
    buttonTitle: "",
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.TypeScript,
      allTags.Firebase,
      allTags.Android,
      allTags.iOS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/Websites/Mega%20Base%20Lotto.svg?raw=true.svg",
    title: "Mega Base Lotto",
    description:
      "A modern crypto based lottery website which offers users an engaging and user-friendly platform to participate in exciting lottery games, with a sleek and responsive design that ensures a seamless experience across all devices.",
    button1Title: "Visit Website",
    onClick1: () => window.open("https://megalotto.netlify.app/", "_blank"),
    tags: [
      allTags.WebApp,
      allTags.ReactJS,
      allTags.JavaScript,
      allTags.TailwindCSS,
    ],
  },
  {
    image:
      "https://github.com/InnspirovateTech/Mockups/blob/main/MobileApps/Link%20Wise.svg?raw=true",
    title: "Linking Wise",
    description:
      "An expense management app where you can create groups, add friends, and manage expenses in all currencies. It provides a simple and efficient way to track and share finances with others.",
    buttonTitle: "",
    tags: [
      allTags.MobileApp,
      allTags.ReactNative,
      allTags.TypeScript,
      allTags.Firebase,
      allTags.Android,
      allTags.iOS,
    ],
  },
];
