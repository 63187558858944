import React from "react";
import { IconType } from "react-icons";
import {
  FaReact,
  FaNodeJs,
  FaWordpress,
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaDatabase,
  FaSwift,
  FaPython,
  FaDocker,
} from "react-icons/fa";
import {
  SiNextdotjs,
  SiTypescript,
  SiExpress,
  SiPostgresql,
  SiMongodb,
  SiShopify,
  SiWebflow,
  SiFlutter,
  SiMicrosoftsqlserver,
  SiFirebase,
  SiDotnet,
  SiTailwindcss,
  SiMui,
  SiBootstrap,
  SiApollographql,
  SiCanvas,
  SiCloudflare,
  SiGithubactions,
  SiJenkins,
  SiKubernetes,
  SiNginx,
  SiPm2,
  SiRedis,
  SiSocketdotio,
  SiStrapi,
  SiAwslambda,
  SiAmazonec2,
  SiAmazons3,
  SiAmazon,
  SiStripe,
  SiAppstore,
  SiGoogleplay,
  SiExpo,
  SiGoogleanalytics,
  SiApple,
  SiGooglepay,
  SiPaypal,
  SiRazorpay,
  SiSquare,
} from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";
import { BiLogoFlask } from "react-icons/bi";

// Define the tech stack data structure
interface Tech {
  name: string;
  icon: IconType;
}

const techStack = {
  frontend: [
    { name: "Next.js", icon: SiNextdotjs },
    { name: "React.js", icon: FaReact },
    { name: "React Native", icon: TbBrandReactNative },
    { name: "Expo", icon: SiExpo },
    { name: "Swift", icon: FaSwift },
    { name: "Flutter", icon: SiFlutter },
    { name: "JavaScript", icon: FaJs },
    { name: "TypeScript", icon: SiTypescript },
    { name: "Fabric.js", icon: SiCanvas },
    { name: "HTML", icon: FaHtml5 },
    { name: "Tailwind CSS", icon: SiTailwindcss },
    { name: "CSS", icon: FaCss3Alt },
    { name: "Material UI", icon: SiMui },
    { name: "Bootstrap", icon: SiBootstrap },
  ],
  backend: [
    { name: "Web API", icon: SiDotnet },
    { name: "Apollo GraphQL", icon: SiApollographql },
    { name: "Node.js", icon: FaNodeJs },
    { name: "Express.js", icon: SiExpress },
    { name: "Python", icon: FaPython },
    { name: "Flask", icon: BiLogoFlask },
    { name: "AWS Lambda", icon: SiAwslambda },
    { name: "AWS EC2", icon: SiAmazonec2 },
    { name: "AWS S3", icon: SiAmazons3 },
    { name: "PM2", icon: SiPm2 },
    { name: "Socket.io", icon: SiSocketdotio },
    { name: "Firebase Functions", icon: SiFirebase },
  ],
  cms: [
    { name: "WordPress", icon: FaWordpress },
    { name: "Shopify", icon: SiShopify },
    { name: "Wix", icon: SiWebflow },
    { name: "Strapi", icon: SiStrapi },
  ],
  database: [
    { name: "Microsoft SQL Server", icon: SiMicrosoftsqlserver },
    { name: "MySQL", icon: FaDatabase },
    { name: "Firebase Firestore", icon: SiFirebase },
    { name: "MongoDB", icon: SiMongodb },
    { name: "PostgreSQL", icon: SiPostgresql },
    { name: "Redis", icon: SiRedis },
  ],
  devops: [
    { name: "AWS", icon: SiAmazon },
    { name: "Docker", icon: FaDocker },
    { name: "Kubernetes", icon: SiKubernetes },
    { name: "PM2", icon: SiPm2 },
    { name: "Nginx", icon: SiNginx },
    { name: "Cloudflare", icon: SiCloudflare },
    { name: "GitHub Actions", icon: SiGithubactions },
    { name: "CI/CD Pipelines", icon: SiJenkins },
    { name: "App Store Connect", icon: SiAppstore },
    { name: "Google Play Console", icon: SiGoogleplay },
  ],
  analytics: [
    { name: "Google Analytics", icon: SiGoogleanalytics },
    { name: "Firebase Analytics", icon: SiFirebase },
  ],
  paymentGateways: [
    { name: "Stripe", icon: SiStripe },
    { name: "Google Pay", icon: SiGooglepay },
    { name: "Apple Pay", icon: SiApple },
    { name: "PayPal", icon: SiPaypal },
    { name: "Razorpay", icon: SiRazorpay },
    { name: "Square", icon: SiSquare },
  ],
};

// Props interface for the TechBadge component
interface TechBadgeProps {
  name: string;
  Icon: IconType;
}

// Badge component with icons
const TechBadge: React.FC<TechBadgeProps> = ({ name, Icon }) => (
  <div className="flex items-center bg-white text-black font-body rounded-full px-4 py-2 m-2 text-sm">
    <Icon className="mr-2 text-xl" />
    {name}
  </div>
);

// Props interface for the TechStackSection component
interface TechStackSectionProps {
  title: string;
  items: Tech[];
}

// Section component to display the list of tech badges
const TechStackSection: React.FC<TechStackSectionProps> = ({
  title,
  items,
}) => (
  <div className="w-full p-4">
    <h3 className="text-lg font-bold  text-gradient mb-4 text-center">
      {title}
    </h3>
    <div className="flex flex-wrap justify-center">
      {items.map((tech) => (
        <TechBadge key={tech.name} name={tech.name} Icon={tech.icon} />
      ))}
    </div>
  </div>
);

// Main component to render the tech stack
const TechStack: React.FC = () => {
  return (
    <div className="flex align-center justify-center p-8 rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <TechStackSection title="Front End" items={techStack.frontend} />
        <TechStackSection title="Back End" items={techStack.backend} />
        <TechStackSection
          title="Payment Gateways"
          items={techStack.paymentGateways}
        />
        <TechStackSection title="CMS" items={techStack.cms} />
        <TechStackSection title="Database" items={techStack.database} />
        <TechStackSection title="DevOps" items={techStack.devops} />
        <TechStackSection title="Analytics" items={techStack.analytics} />
      </div>
    </div>
  );
};

export default TechStack;
